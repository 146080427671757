import React, { useEffect } from 'react';
import {
    createBrowserRouter,
    RouterProvider,
    useLocation,
} from "react-router-dom";

import Root from "./routes/root";
import { Index } from "./routes/Index";
import { Condiciones } from "./routes/Condiciones";
import { Politicas } from "./routes/Politicas";
import { EmailConfirmation, loader as emailConfirmationTokenLoader } from "./routes/EmailConfirmation";
import { PasswordReset, loader as passwordResetTokenLoader } from "./routes/PasswordReset";
import ErrorPage from "./components/error-page";

const router = createBrowserRouter([
    {
      path: "",
      element: <Root/>,
      errorElement: <ErrorPage/>,
      children: [
        {
          path: "/",
          element: <Index />,
        },
        {
          path: "/condiciones",
          element: <Condiciones />,
        },
        {
          path: "/politicas",
          element: <Politicas />,
        },
        {
          path: "/email-confirmation/:token",
          element: <EmailConfirmation />,
          loader: emailConfirmationTokenLoader,
        },
        {
          path: "/password-reset/:token",
          element: <PasswordReset />,
          loader: passwordResetTokenLoader,
        },
      ],
    },
]);

export const AllergienApp = () => {
  return (
    <>
      <RouterProvider router={router} />
    </>
  )
}