import React from 'react'

export const Index = () => {
  return (
    <div className="allergien-index-main-container">
      <div className="allergien-index-sub-container">
        <div className="allergien-index-logo"></div>
        <div className="allergien-index-name">APP Allergien</div>
      </div>
    </div>
  )
}
