import React from 'react'

export const Condiciones = () => {
  return (
    <>
      <div className="condiciones-politicas-slider-container">Términos y Condiciones</div>
      <div className='container mt-5'>
        <div className="condiciones-politicas-text">
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec eu sapien dictum, ultricies libero viverra, viverra risus. Pellentesque rhoncus leo in auctor vehicula. Suspendisse et magna non nisl pretium iaculis. Suspendisse id lectus orci. Vivamus laoreet velit feugiat, bibendum mauris at, blandit ipsum. Pellentesque dictum pharetra aliquet. Integer vitae posuere est. Curabitur venenatis auctor justo at rhoncus. Sed massa leo, tincidunt in condimentum et, tincidunt eleifend augue. Nam sit amet porttitor erat, sit amet vulputate mi. Proin lorem sem, fermentum at molestie sit amet, dapibus a tortor. Integer fringilla, sapien quis maximus pellentesque, mauris leo dictum augue, ac tincidunt neque nunc a diam. Nulla facilisi. Nam quis auctor magna. Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
          <p>Mauris dictum ornare vestibulum. Sed turpis purus, cursus in pellentesque ut, ullamcorper vel tellus. Nullam bibendum tellus ipsum, at consequat felis pharetra sit amet. Ut nec mi turpis. Phasellus ut est tempor, interdum mauris id, euismod orci. Integer blandit lacus arcu, nec egestas nibh cursus id. In suscipit consequat ultricies. Aenean sit amet mollis elit, ac ullamcorper velit. Nunc mauris ante, varius at mi ac, cursus convallis nunc. Vivamus venenatis blandit nulla, ut molestie sapien imperdiet id. Curabitur ultrices ex ex, et laoreet tortor dictum vulputate. Integer ac suscipit augue. Fusce vitae nibh sagittis, euismod orci in, hendrerit libero. Etiam tempor, ligula in lacinia vestibulum, odio dolor commodo justo, a lobortis enim lorem nec justo. Sed pharetra lacus neque, et porta sapien tincidunt quis. Phasellus finibus elementum dui molestie tincidunt.</p>
          <p>Mauris feugiat porttitor dictum. Vestibulum ultricies congue laoreet. Sed sit amet nulla quis eros egestas bibendum non vitae purus. Pellentesque varius nulla ut quam consequat vestibulum. Suspendisse faucibus mattis tellus eu mollis. Nulla in nisl tincidunt, egestas sapien vitae, lobortis sapien. Etiam laoreet orci libero, at cursus ipsum sollicitudin a. Phasellus at euismod dui. Sed diam felis, bibendum at facilisis in, tincidunt vitae urna. Phasellus mattis tellus sit amet mi efficitur, at bibendum diam commodo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Quisque in sem libero. Vestibulum eleifend ipsum vel sem porta, vitae rutrum arcu dapibus. Sed ante metus, vestibulum sit amet sapien eu, convallis convallis diam. Aliquam efficitur mi ac purus mattis consectetur. Nullam consequat condimentum ex vel semper. Nunc bibendum vehicula condimentum. Proin scelerisque, orci vitae tincidunt maximus, arcu nisl dictum sem, non iaculis odio est id dui. Cras non magna mollis, dictum urna ac, tempor ex. Vestibulum sed urna dolor. Nam vehicula dui sit amet mauris sollicitudin imperdiet at eu lacus.</p>
          <p>Sed ipsum nisi, luctus nec massa a, gravida tincidunt leo. Nam id ligula nunc. Phasellus ac orci massa. Quisque et lacus sapien. Vivamus porta elementum dolor sit amet molestie. Nam vel congue enim. Ut facilisis ullamcorper leo, quis placerat dui blandit quis. Morbi lacinia et lectus non auctor. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras blandit neque vitae enim sodales, vel pretium tortor tincidunt. Pellentesque lobortis rutrum metus ut dapibus. Nam finibus orci diam, non vestibulum ante imperdiet non.</p>
        </div>
      </div>
    </>
  )
}
