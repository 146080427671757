import React from 'react'

export const Politicas = () => {
  return (
    <>
      <div className="condiciones-politicas-slider-container">Politica de privacidad</div>
      <div className='container mt-5'>
        <div className="condiciones-politicas-text">
          <p className="condiciones-politicas-main-text">Aviso de Privacidad de Datos Personales para Allergien con domicilio en Carretera Nacional 6501 consultorio 611. Col. La Estanzuela, Monterrey, Nuevo León 64988</p>
          <p className="condiciones-politicas-main-text">En cumplimiento con la Ley Federal de Protección de Datos Personales en Posesión de Particulares publicada en el Diario Oficial de la Federación el 05 de julio de 2010 (en lo sucesivo la "Ley"), le informamos que Allergien será el responsable de recabar, velar, utilizar, almacenar, proteger y/o transferir sus datos personales.</p>
          <p className="condiciones-politicas-header">1. Datos personales que se recaban</p>
          <p className="condiciones-politicas-main-text">Allergien podrá recabar y, en su caso, tratar de manera enunciativa más no limitativa los siguientes datos personales, dependiendo de la relación que con usted exista:</p>
          <ul className="condiciones-politicas-list">
            <li>Nombre</li>
            <li>Edad</li>
            <li>Género</li>
            <li>Domicilo</li>
            <li>Fecha	de Nacimiento</li>
            <li>Estado Civil</li>
            <li>Ocupación</li>
            <li>Correo electrónico</li>
            <li>Teléfono</li>
            <li>Datos	familiares</li>
            <li>Datos	bancarios, financieros y/o patrimoniales</li>
          </ul>
          <p className="condiciones-politicas-header">2. Finalidades del tratamiento de los datos personales</p>
          <p className="condiciones-politicas-main-text">Allergien recaba, utiliza, almacena o transfiere sus datos personales, en la medida en que las leyes aplicables lo permiten, para llevar a cabo de manera enunciativa más no limitativa lo siguiente:</p>
          <ul className="condiciones-politicas-list">
            <li>Ofrecer	bienes y servicios.</li>
            <li>Determinar la satisfacción, perfil y las necesidades de sus clientes.</li>
            <li>Conocer la participación de Allergien en el mercado.</li>
            <li>Desarrollar nuevos productos y servicios.</li>
            <li>Conformar expedientes de clientes.</li>
            <li>Distribuir comunicaciones y promociones.</li>
            <li>Analizar su información curricular para posibles oportunidades laborales.</li>
            <li>Cumplir con las disposiciones legales aplicables.</li>
          </ul>
          <p className="condiciones-politicas-main-text">En caso de que usted no esté de acuerdo con alguna o varias de las finalidades anteriormente descritas, le pedimos hacernos saber dicha negativa a la dirección de correo electrónico siguiente: soporte@allergien.mx</p>
          <p className="condiciones-politicas-main-text">Así mismo le informamos que sus datos personales serán conservados y tratados únicamente por el tiempo necesario, a fin de cumplir con las finalidades descritas y/o de conformidad con lo que establezcan las disposiciones legales aplicables.</p>
          <p className="condiciones-politicas-header">3. Seguridad de los datos personales</p>
          <p className="condiciones-politicas-main-text">Allergien implementará las medidas de seguridad, técnicas, administrativas y físicas, necesarias para proteger sus datos personales y evitar su daño, pérdida, alteración, destrucción o el uso, acceso o tratamiento no autorizado. Únicamente el personal autorizado, que ha cumplido y observado los correspondientes requisitos de confidencialidad, podrá participar en el tratamiento de sus datos personales. El personal autorizado tiene prohibido permitir el acceso de personas no autorizadas y utilizar sus datos personales para fines distintos a los establecidos en el presente Aviso de Privacidad.</p>
          <p className="condiciones-politicas-header">4. Comunicaciones y Transferencias de datos personales</p>
          <p className="condiciones-politicas-main-text">Como parte de las operaciones empresariales llevadas a cabo por Allergien, ésta podrá transferir a terceros, nacionales o extranjeros, algunos o todos sus datos personales. Dichos terceros podrán ser asesores profesionales externos y/u otros prestadores de servicios. En caso de que usted no desee que Allergien lleve a cabo la transferencia referida en el presente párrafo le pedimos dirigir dicha solicitud a la dirección de correo electrónico siguiente: soporte@allergien.mx</p>
          <p className="condiciones-politicas-main-text">Allergien se asegurará a través de la firma de convenios y/o la adopción de otros documentos vinculantes, que dichos terceros mantengan medidas de seguridad, administrativas, técnicas y físicas adecuadas para resguardar sus datos personales, así como que dichos terceros únicamente utilicen sus datos personales para las finalidades para las cuales fueron contratados y de conformidad con el presente Aviso de Privacidad.</p>
          <p className="condiciones-politicas-header">5. Derechos que corresponden a los titulares de Datos Personales</p>
          <p className="condiciones-politicas-main-text">Usted, como titular de datos personales, podrá ejercitar los derechos de acceso, rectificación, cancelación y oposición (conjuntamente los "Derechos ARCO") respecto al tratamiento de sus datos personales. En virtud de lo anterior, si desea cualquier forma de acceso, rectificación, cancelación y/u oposición respecto de los datos personales con los que cuenta Allergien, lo podrá hacer a través de la cuenta de correo: soporte@allergien.mx</p>
          <p className="condiciones-politicas-main-text">De conformidad con lo establecido en la Ley, la solicitud para ejercer cualquiera de los Derechos ARCO deberá contener:</p>
          <p className="condiciones-politicas-main-text">Nombre completo y domicilio del titular de los Datos Personales, u otro medio para comunicarle la respuesta a su solicitud.</p>
          <p className="condiciones-politicas-main-text">Documentos que acrediten la identidad o la representación legal del titular de los Datos Personales.</p>
          <p className="condiciones-politicas-main-text">Descripción clara y precisa de los Datos Personales respecto de los que se busca ejercer alguno de los derechos antes mencionados.</p>
          <p className="condiciones-politicas-main-text">Cualquier otro elemento o documento que facilite la localización de los Datos Personales.</p>
          <p className="condiciones-politicas-main-text">En el caso de solicitudes de rectificación de Datos Personales, usted deberá indicar las modificaciones a realizarse y/o aportar la documentación que sustente su petición.</p>
          <p className="condiciones-politicas-main-text">Los demás que determine la legislación aplicable.</p>
          <p className="condiciones-politicas-main-text">Así mismo le informamos que, sin perjuicio del ejercicio de cualquiera de los Derechos ARCO, usted podrá revocar, en todo momento, el consentimiento que haya otorgado y que fuere necesario para el tratamiento de sus datos personales mediante solicitud enviada por medio de la cuenta de correo electrónico siguiente: soporte@allergien.mx</p>
          <p className="condiciones-politicas-header">6. Cambios al Aviso de Privacidad</p>
          <p className="condiciones-politicas-main-text">Allergien se reserva el derecho de efectuar, en cualquier momento, modificaciones o actualizaciones al presente aviso de privacidad con el objetivo de incorporar reformas legislativas u opiniones jurisprudenciales, políticas internas, prácticas de mercado, así como requerimientos para la prestación u ofrecimiento de nuestros bienes y servicios. Dichas modificaciones estarán disponibles a través de la página de Internet: <a href="http://app.allergien.mx/politicas">http://app.allergien.mx/politicas</a> o mediante los medios contemplados en la Ley.</p>
        </div>
      </div>
    </>
  )
}
